import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import style from "./Header.module.scss"
import Submenu from "./Submenu"
import cx from "classnames"

const Header = props => {
  const { data, layout, headings } = props
  const allPages = data.prismic.allPages.edges

  const classNames = cx(style.container, {
    [style.isInIndexLayout]: layout === "index",
    [style.isInPageLayout]: layout === "page",
  })

  return (
    <header className={classNames}>
      {layout === "page" && <Submenu headings={headings} />}

      <p className={style.hiddenFontItalic}>Hidden font italic</p>

      <nav className={style.navigation}>
        <ul className={style.list}>
          {allPages &&
            allPages
              .sort(
                (pageA, pageB) => pageA.node.menu_order - pageB.node.menu_order
              )
              .map((page, index) => (
                <li key={index} className={style.item}>
                  <Link to={`/${page.node._meta.uid}/`} className={style.link}>
                    {page.node.page_title[0].text}
                  </Link>
                </li>
              ))}
          <li className={style.item}>
            <a className={style.link} href="https://remora.ch/" target="_blank" rel="noopener noreferrer">
              Remora AG
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default props => {
  const StaticHeaderQuery = graphql`
    {
      prismic {
        allPages {
          edges {
            node {
              _meta {
                uid
              }
              page_title
              menu_order
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={`${StaticHeaderQuery}`}
      render={data => <Header data={data} {...props} />}
    />
  )
}

Header.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allPages: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              _meta: PropTypes.shape({
                uid: PropTypes.string.isRequired,
              }).isRequired,
              menu_order: PropTypes.number.isRequired,
              page_title: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string.isRequired,
                }).isRequired
              ).isRequired,
            }).isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  layout: PropTypes.oneOf(["index", "page"]),
  headings: PropTypes.arrayOf(PropTypes.array),
}

Header.defaultProps = {
  isInPageLayout: false,
}
